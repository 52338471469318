<template>
    <div class="kb-form-column kb-form-column-title">
      <label class="kb-form-label">
        <strong class="kb-form-label-text">{{ title }}</strong>
      </label>
    </div>


        <div v-for="(item, idx) in options1" class="kb-form-check kb-form-check-inline-center" :key="idx" >
          <input
              type="radio" class="kb-form-check-input" :name="`chk_${item.div}`"
              :id="`chk_${item.div}_${idx}`"
              :value="item.cd"
              :checked="item.cd === props.value1 && item.div === props.div"
              @click="updateValue"
              :disabled="disabled"
          />
          <label :for="`chk_${item.div}_${idx}`" class="kb-form-check-label"><span class="text">{{ item.cdNm }}</span></label>


      <!-- kb-form-dropdown -->
<!--      <div v-if="options1 && options1.length > 0" class="kb-form-dropdown" :class="{'is-active': toggle1}">-->
<!--        <button class="kb-form-dropdown-btn" @click.stop="toggleOptions(1)" :disabled="disabled">-->
<!--          <span class="text">{{ selectedValue1 }}</span><i class="icon-arrow"></i>-->
<!--        </button>-->
<!--        <div class="kb-form-dropdown-options" :style="widthStyle">-->
<!--          <div class="dropdown-option">-->
<!--            <ul class="dropdown-option-list">-->
<!--              <li v-for="(option, index) in options1" class="dropdown-option-item" :key="index">-->
<!--                <a href="javascript:" class="dropdown-option-link" :class="{'is-active': value1 === option[sequenceKey1]}" @click.stop="selectOption(1, index)"><span class="dropdown-option-text">{{ option[nameKey1] }}</span></a>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      -->
<!--      <template v-if="useSubText">-->
<!--        <span v-if="customSubText" class="kb-form-subtext">{{ customSubText }}</span>-->
<!--        <span v-else-if="subText" class="kb-form-subtext">{{ subText }}</span>-->
<!--      </template>-->
<!--      <slot name="search-func" />-->

    </div>
</template>

<script>
import {computed} from 'vue';

export default {
  name: 'InputSelect',
  props: {
    value1: [String, Number],
    title: String,
    options1: Array,
    div : String,
    sequenceKey1: {type:String, required:true},
    nameKey1: String,
    isRelated: {type: Boolean, default: true}, // 1번째 셀렉트박스가 선택되어있어야 2번째 셀렉트 박스 활성화
    placeholder1: String,
    customSubText: String,
    disabled: Boolean,
    useSubText: {type: Boolean, default: true},
    width: Number,
  },
  emits: ['update:value1', 'selected','update:modelValue'],
  setup(props, {emit}) {
    // 구분 값

    // const selectedValue1 = computed(() => {
    //   if (props.options1) {
    //     const target = props.options1.find(x => {
    //       if (x[props.sequenceKey1] === props.value1) return x;
    //     });
    //     if (target) {
    //       return target[props.nameKey1];
    //     }
    //   }
    //   return (props.placeholder1 ? props.placeholder1 : '');
    // });

    // const setValue = () => {
    //   if (props.options1) {
    //     const target = props.options1.find(x => {
    //       if (x[props.sequenceKey1] === props.value1) return x;
    //     });
    //     if (target) {
    //       return target[props.nameKey1];
    //     }
    //   }
    // };

    const updateValue = (e) => {
      let val = e.target.value;
      console.log(val);
      emit('update:modelValue',val);
    };
    // 분야 값
    // const selectedValue2 = computed(() => {
    //   if (props.options2) {
    //     const target = props.options2.find(x => {
    //       if (x[props.sequenceKey2] === props.value2) return x;
    //     });
    //     if(target){
    //       return target[props.nameKey2];
    //     }
    //   }
    //   return (props.placeholder2 ? props.placeholder2 : '');
    // });
    // 선택된 구분과 분야 노출해주는 텍스트
    // const subText = computed(() => {
    //   let first = '', second = '';
    //   if (selectedValue1.value && selectedValue1.value !== props.placeholder1) {
    //     first = selectedValue1.value;
    //   }
    //   if (selectedValue2.value && selectedValue2.value !== props.placeholder2) {
    //     second = ` - ${selectedValue2.value} 학습`;
    //   }
    //   return `${first}${second}`
    // });
    // 선택 행위 함수
    const selectOption = (order, index) => {

        if (props.value1 !== props.options1[index][props.sequenceKey1]) {
          emit('update:value1', props.options1[index][props.sequenceKey1]);
        }

    };

    // props.value1 과 value2 타입 체크 후 숫자일땐 0 아닐떈 null
    // const typeCheck = (value) => {
    //   if (typeof value === 'number') {
    //     return 0;
    //   } else {
    //     return null;
    //   }
    // };

    // const toggleOptions = (order) => {
    //   emit('update:toggle1', !props.toggle1);
    // };


    const checkDisabled = computed(() => {
      if (!props.disabled) {
        if (props.isRelated) {
          return props.value1 <= 0;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });



    // const widthStyle = computed(() => {
    //   if(props.width > 0){
    //     return {width: `${props.width}px`};
    //   }
    //   return {};
    // });

    return {
      props,
      // toggleOptions,
      updateValue,
      // selectedValue1,
      // selectedValue2,
      selectOption,
      // subText,
      checkDisabled,
      // widthStyle
    }
  }
};
</script>