<template>
  <div class="popup-container is-active"  :class="{'is-active': modelValue}">
    <div class="popup" id="popup-hrdcolud-h0205">
      <div class="popup-inner">
        <div>
          <header class="popup-header">
            <h3 class="title">직원검색</h3>
          </header>
          <div class="popup-content">
            <div class="member-search-container">
              <div class="kb-form-fields field-01">
                <div class="kb-form-row" >
                  <div class="kb-form-column kb-form-column-title" style="justify-content: center;">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">부점/부서 </span>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <div class="kb-form-search-field">
                      <input v-model="search.deptNm" type="text" class="kb-form-search-input" placeholder="부점 및 부서명 검색" @keyup.enter="clickSearch" />
                      <button class="kb-form-search-btn"><i class="icon-search"></i></button>
                    </div>
                  </div>
                  <div class="kb-form-column kb-form-column-title" style="justify-content: center;">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">직원</span>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <div class="kb-form-search-field">
                      <input v-model="search.lrnerNm" type="text" class="kb-form-search-input" placeholder="직원명 및 직원번호 검색" @keyup.enter="clickSearch" />
                      <button class="kb-form-search-btn"><i class="icon-search"></i></button>
                    </div>
                  </div>
                </div>

              </div>
              <div class="kb-form-buttons">
                <button class="kb-btn kb-btn-primary kb-btn-lg" @click="clickSearch"><span class="text">검색</span></button>
              </div>
              <div v-if="paging.totalCount > 0" class="search-results">
                <section class="popup-section">
                  <header class="section-header">
                    <h4 class="title">검색결과 <strong class="text text-muted">{{ paging.totalCount }}</strong></h4>
                  </header>
                  <div class="section-content">
                    <!-- TODO::스타일 추후 제거 -->
                    <div class="kb-table kb-table-striped member-tablelist" style="height: 300px; overflow:scroll;">
                      <table>
                        <colgroup>
                          <col style="width:auto;" />
                          <col style="width:auto;" />
                          <col style="width:auto;" />
                          <col style="width:auto;" />
                          <col style="width:160px;" />
                        </colgroup>
                        <thead>
                        <tr>
                          <th><span class="th-title">지점코드/지점명</span></th>
                          <th><span class="th-title">직원명</span></th>
                          <th><span class="th-title">직원번호</span></th>
                          <th><span class="th-title">직급</span></th>
                          <th class="text-center">
                            <button v-if="!checkAll" class="kb-btn-check" @click.stop="clickCheckAll"><span class="th-title text-primary">전체선택</span></button>
                            <button v-else class="kb-btn-check" @click.stop="clickUnCheckAll"><span class="th-title text-primary">전체해제</span></button>
                          </th>
                        </tr>
                        </thead>
                        <tbody v-if="items && items.length > 0">
                        <tr v-for="(item, idx) in items" :key="`tr-${idx}`">
                          <td><strong class="td-text">{{ item.deptCd || item.deptNm ? `${item.deptCd || '-'} / ${item.deptNm || '-'}` : '-' }}</strong></td>
                          <td><strong class="td-text">{{ item.lrnerNm }}</strong></td>
                          <td><strong class="td-text">{{ item.lrnerId }}</strong></td>
                          <td><strong class="td-text">{{ `${item.jbgdNm || '-'}` }}</strong></td>
                          <td class="text-center">
                            <div class="kb-form-check">
                              <input type="checkbox" class="kb-form-check-input" name="chkMember"
                                     v-model="checkUsers"
                                     :value="item"
                                     @click.stop="checkLength"
                              />
                            </div>
                          </td>
                        </tr>
                        </tbody>
                        <tbody v-else>
                        <tr>
                          <td><span class="td-text text-muted">검색 결과가 없습니다.</span></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                <section class="popup-section">
                  <header class="section-header">
                    <h4 class="title">교육대상 <strong v-if="checkUsers && checkUsers.length > 0" class="text text-muted">{{ checkUsers.length }}</strong></h4>
                  </header>
                  <div class="section-content">
                    <div class="kb-table kb-table-striped member-tablelist" style="height: 300px; overflow:scroll;">
                      <table>
                        <template v-if="checkUsers && checkUsers.length > 0">
                          <colgroup>
                            <col style="width:auto;" />
                            <col style="width:auto;" />
                            <col style="width:auto;" />
                            <col style="width:auto;" />
                            <col style="width:160px;" />
                          </colgroup>
                          <thead>
                          <tr>
                            <th><span class="th-title">지점코드/지점명</span></th>
                            <th><span class="th-title">직원명</span></th>
                            <th><span class="th-title">직원번호</span></th>
                            <th><span class="th-title">직급</span></th>
                            <th class="text-center">
                              <button class="kb-btn-check" @click.stop.prevent="clickUnCheckAll"><span class="th-title text-primary">전체해제</span></button>
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(chk, idx) in checkUsers" :key="`tr-${idx}`">
                            <td><strong class="td-text">{{ chk.deptCd || chk.deptNm ? `${chk.deptCd || '-'} / ${chk.deptNm || '-'}` : '-' }}</strong></td>
                            <td><strong class="td-text">{{ chk.lrnerNm }}</strong></td>
                            <td><strong class="td-text">{{ chk.lrnerId }}</strong></td>
                            <td><strong class="td-text">{{ `${chk.jbgdNm || '-'}` }}</strong></td>
                            <td class="text-center">
                              <div class="kb-form-check">
                                <input type="checkbox" class="kb-form-check-input" name="chkMember" :checked="true" @click.prevent.stop="unCheck(idx)"/>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </template>
                        <tbody v-else>
                        <tr>
                          <td><span class="td-text text-muted">공유 대상이 없습니다.</span></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

<!--                <section class="popup-section">-->
<!--                  <header class="section-header">-->
<!--                    <h4 class="title">공유 메시지</h4>-->
<!--                  </header>-->
<!--                  <div class="section-content">-->
<!--                    <div class="segment-box">-->
<!--                      <textarea v-model="notiCn" name="" id="" cols="30" rows="6" class="kb-form-control" placeholder="전송할 메시지를 입력해주세요."></textarea>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </section>-->

<!--                <section class="popup-section">-->
<!--                  <header class="section-header">-->
<!--                    <h4 class="title">공유방법</h4>-->
<!--                  </header>-->
<!--                  <div class="section-content">-->
<!--                    <div class="kb-form-fields">-->
<!--                      <div class="kb-form-row">-->
<!--                        <div class="kb-form-column kb-form-column-title">-->
<!--                          <label class="kb-form-label">-->
<!--                            <strong class="kb-form-label-text">구분</strong>-->
<!--                          </label>-->
<!--                        </div>-->
<!--                        <div class="kb-form-column">-->
<!--                          <div class="kb-form-group">-->
<!--                            <div class="kb-form-check kb-form-check-inline">-->
<!--                              <input v-model="notiPfmCd" type="checkbox" class="kb-form-check-input" name="chk_01" id="chk_01_01" :value="notiPfmCds.liiv" />-->
<!--                              <label for="chk_01_01" class="kb-form-check-label"><span class="text">리브똑똑</span></label>-->
<!--                            </div>-->
<!--                            <div class="kb-form-check kb-form-check-inline">-->
<!--                              <input v-model="notiPfmCd" type="checkbox" class="kb-form-check-input" name="chk_01" id="chk_01_02" :value="notiPfmCds.wiseNet" />-->
<!--                              <label for="chk_01_02" class="kb-form-check-label"><span class="text">메신저 쪽지</span></label>-->
<!--                            </div>-->
<!--                            <div class="kb-form-check kb-form-check-inline">-->
<!--                              <input v-model="notiPfmCd" type="checkbox" class="kb-form-check-input" name="chk_01" id="chk_01_03" :value="notiPfmCds.wiseEml" />-->
<!--                              <label for="chk_01_03" class="kb-form-check-label"><span class="text">내부메일</span></label>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </section>-->
              </div>
            </div>
          </div>
          <div v-if="paging.totalCount > 0" class="popup-buttons">
            <a v-if="checkUsers.length < 1" href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" disabled><span class="text">선택하기</span></a>
            <a v-else href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click.stop="clickSelect"><span class="text">선택하기</span></a>
          </div>
        </div>
      </div>

      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>

</template>

<script>
import {computed, onMounted, reactive, ref} from 'vue';
import {useStore} from 'vuex';
import {ACT_GET_USERS_LIST} from '@/store/modules/user/user';
import {compareArrays, getItems, getPaging, lengthCheck} from '@/assets/js/util';
// import {notiPfmCds} from '@/assets/js/modules/noti/noti-common';
import {useAlert} from '@/assets/js/modules/common/alert';
// import {insertCourseAct} from '@/assets/js/modules/course/course-common';
// import {actTyCdDcds} from '@/assets/js/modules/common/common';

export default {
  name: 'ApplyDeptReqLrnModal',
  props: {
    modelValue: Boolean,
    selectTrgtList: Array,
  },
  emits: ['update:modelValue', 'update:selectTrgtList'],
  setup(props, {emit}) {
    const store = useStore();
    const { showMessage} = useAlert();

    const search = reactive({
      lrnerNm : '',
      deptNm: ''
    });



    const notiCn = ref('');
    const items = ref([]);
    const paging = ref({pageNo: 1, pageSize: 99999, totalCount: 0, hasMore: false});
    // const notiPfmCd = ref([notiPfmCds.liiv , notiPfmCds.wiseNet]);
    const session = computed(() => store.state.auth.session);

    const checkUsers = ref([]);

    const checkAll = computed(() => {
      let cnt = 0;
      items.value.map(item => {
        checkUsers.value.filter(user => {
          if (item.lrnerId === user.lrnerId) cnt++;
        });
      });

      if (cnt == items.value.length) return true;
      return false;
    });

    const clickCheckAll = () => {
      items.value.forEach(x => {
        if (checkUsers.value.length < 10 && checkUsers.value.filter(y => x.lrnerId === y.lrnerId).length === 0) {
          checkUsers.value.push(x);
        }
      });
    };

    const clickUnCheckAll = () => {
      checkUsers.value = [];
    };

    const unCheck = (idx) => {
      checkUsers.value.splice(idx, 1);
    };

    const getUserList = () => {
      store.dispatch(`auth/${ACT_GET_USERS_LIST}`, {
        lrnerNm: search.lrnerNm,
        deptNm: search.deptNm,
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if(lengthCheck(res)) {
          items.value = getItems(res).filter(x => x.lrnerId != session.value.lrnerId);
          paging.value = getPaging(res);
        } else {
          items.value = [];
          paging.value.totalCount = 0;
          showMessage("검색결과가 없습니다.<br> 검색어를 확인후 다시 검색해주세요.");
        }
      }).catch(e => {
        items.value = [];
        console.error(e);
        showMessage("검색 중 오류 입니다. 다시 시도해주세요.");
      })
    };

    const clickSearch = () => {
      if (search.lrnerNm === '' && search.deptNm === '') {
        showMessage('검색할 키워드를 입력해주세요.');
        return;
      } else if (search.lrnerNm !== '' && search.lrnerNm.length < 2) {
        showMessage('키워드를 2자 이상 입력해주세요.');
        return;
      } else if (search.deptNm !== '' && search.deptNm.length < 2) {
        showMessage('키워드를 2자 이상 입력해주세요.');
        return;
      } else {
        getUserList();
      }
    };

    const clickSelect = () => {
      let result = false;

      // if(notiPfmCd.value.length < 1){
      //   showMessage('공유방법을 1개이상 선택해주세요.');
      // } else if(!notiCn.value) {
      //   showMessage('공유메시지를 입력해주세요.');
      // } else{
      //   result = true;
      // }
      if(checkUsers.value.length > 0){
        result = true;
      }else{
        showMessage("교육대상 인원이 선택되지 않았습니다.");
        return;
      }

      if(result) {
        if(checkUsers.value.length > 0) {
          console.log("checkUsers: " ,checkUsers.value);
          emit('update:selectTrgtList',checkUsers.value);
          closeModal();
        }

      }
    }


    const checkLength = (event) => {
      if (event.target.checked && checkUsers.value.length === 50) {
        showMessage({
          text: '최대 50명까지 입과할 수 있습니다.'
        });
        event.preventDefault();
      }
    }

    const closeModal = () => {
      emit('update:modelValue', false);
    };

    onMounted(() => {
      if(props.selectTrgtList.length>0){
        checkUsers.value = props.selectTrgtList;
      }
      // setParams(crseActParam, props.data);
    });

    return {
      // notiPfmCds,
      // notiPfmCd,
      // notiParams,
      notiCn,
      search,
      items,
      paging,
      checkAll,
      checkUsers,
      unCheck,
      clickCheckAll,
      clickUnCheckAll,
      clickSelect,
      clickSearch,
      closeModal,
      compareArrays,
      checkLength
    }
  }
};
</script>