<template>

<!--  <LxpMobileHeader v-if="isMobile">-->
<!--    <template v-slot:left>-->
<!--      <div class="util util-back">-->
<!--        <router-link :to="{name: 'ApplyDeptReqTrain'}" class="util-actions util-actions-back">-->
<!--          <i class="icon-history-back"></i>-->
<!--        </router-link>-->
<!--      </div>-->
<!--    </template>-->
<!--  </LxpMobileHeader>-->

  <main class="kb-main" id="kb-sdl-dept" >
    <!-- main-header -->
    <lxp-main-header :show-back="true"  :show-custom-title="true" :title="`부서요청교육 신청`" >

    </lxp-main-header>
    <template v-if="!isMobile">
<!--      <div class="main-header">-->
<!--        &lt;!&ndash; main-header > header-top &ndash;&gt;-->

<!--        <div class="header-top main-component">-->

<!--          <div class="header-column">-->
<!--            <router-link :to="{ name: 'ApplyDeptReqTrain' }" class="page-nav">-->
<!--              <i class="icon-kb-nav-arrow"></i>-->
<!--              <span class="text">이전</span>-->
<!--            </router-link>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <!-- //main-header -->
      <!-- main-content -->
      <div class="main-content min-component"  >
        <div class="board-view-container">

          <div class="view-body min-component">

              <div class="content-section ">
                <header class="section-header ">
                  <h3 class="title">요청기본정보</h3>
                </header>
                <div class="section-content">
                  <div class="kb-form-fields kb-form-fields-v2">
                    <div class="kb-form-row">
                      <InputText title="교육명(교육목적)" placeholder="교육명(교육목적" v-model=commInfo.eduGoal :max="400" :disabled="isReadOnly" />
                    </div>
                    <div class="kb-form-row">
                      <InputText title="주요교육내용" placeholder="주요교육내용" v-model=commInfo.eduCont :max="400" :disabled="isReadOnly" />
                    </div>
                    <div class="kb-form-row">
                      <InputText title="직무연관성 및 교육필요성" placeholder="직무연관성 및 교육필요성" v-model=commInfo.trainNeces :max="400" :disabled="isReadOnly" />
                    </div>

                    <div class="kb-form-row">
                      <InputText title="주관처" placeholder="주관처"  v-model="commInfo.compNm" :max="400" :disabled="isReadOnly" />
                      <InputText title="업체담당자 연락처" placeholder="업체담당자 연락처" v-model=commInfo.compInfo :max="30" :disabled="isReadOnly" />

                    </div>

                    <div class="kb-form-row">
                      <InputText title="결제방법" placeholder="결제방법" v-model=commInfo.payMod :max="50" :disabled="isReadOnly" />
                      <InputRadio
                          v-model:value1="commInfo.overseasYn"
                          title="해외연수 여부"
                          :options1="checkOptions"
                          :useSubText="false"
                          sequence-key1="cd"
                          nameKey1="cdNm"
                          div="overseas"
                          :disabled ="isReadOnly"
                      />

                    </div>
                    <div class="kb-form-row">
                    <InputRadio
                        v-model:value1="commInfo.lunchYn"
                        title="중식제공 여부"
                        :options1="checkOptions2"
                        :useSubText="false"
                        sequence-key1="cd"
                        nameKey1="cdNm"
                        div="lunch"
                        :disabled ="isReadOnly"
                    />
                    <InputRadio
                        v-model:value1="commInfo.deptDupYn"
                        title="동일부서 기 참가여부"
                        :options1="checkOptions3"
                        :useSubText="false"
                        sequence-key1="cd"
                        nameKey1="cdNm"
                        div="dup"
                        :disabled ="isReadOnly"
                    />
                    </div>

                    <Upload
                        v-model="addFileList"
                        title="교육안내문 및 기타 서류"
                        placeholder="파일을 등록해주세요."
                        btn-title="파일등록"
                        :max-qty="10"
                        sequence-key="deptReqFileAtchSn"
                        :disabled ="isReadOnly"
                    />

                  </div>
                </div>
              </div>

              <div class="content-section "  v-for="(item, index) in regDistList" :key="index">
                <header class="section-header d-flex " style="justify-content: space-between" >
                  <h3 class="title">과정정보 {{index+1}} </h3>
                  <div style="text-align: right;">
                    <button class="kb-btn kb-btn-xs kb-btn-light-gray" :disabled ="isReadOnly" @click="deleteCrse(index)" ><i class="icon-x"></i></button>
                  </div>
                </header>
                <div class="section-content section-divider"  >

                  <div class="kb-form-fields kb-form-fields-v2">
                    <div class="kb-form-row">
                      <InputText title="과정명" placeholder="과정명"  v-model="item.distCrseNm"  :max="400" :disabled ="isReadOnly"/>


                    </div>
                    <DatepickerRange
                        v-model:bgng-value="item.bgngDt"
                        v-model:end-value="item.endDt"
                        v-model:toggle="toggles.bgngDt"
                        title="교육기간"
                        @update:toggle="closeToggles(toggles, 'bgngDt')"
                        :disabled ="isReadOnly"
                    />
                    <div class="kb-form-row">
                      <InputText title="총 학습일" :is-number="true" placeholder="총 학습일"   v-model="item.totLrnPerid" :max="400" :disabled ="isReadOnly"/>
                      <InputText title="총 학습시간" :is-number="true" placeholder="총 학습시간"  v-model="item.totLrnHr" :max="400" :disabled ="isReadOnly"/>
                    </div>
                    <div class="kb-form-row">
                      <InputText v-model="item.eduCost" title="1인당 교육비용 (Vat포함)"  :isNumber="true" :is-money="true"   placeholder="1인당 교육비용(Vat포함)" :max="400" :disabled ="isReadOnly"/>
                      <InputText   v-model="item.totBgt" title="총 교육비용(Vat 포함)" :isNumber="true" :is-money="true"  placeholder="총 교육비용(Vat 포함)" :max="400" :disabled ="isReadOnly"/>
                    </div>
                    <div class="kb-form-row">
                      <InputRadio
                          v-model:value1="regDistList[index].empInsAplyYn"
                          title="고용보험 환급여부"
                          :options1="[{ cd: 'Y', cdNm: 'Y' ,div:'crse'+index},{ cd: 'N', cdNm: 'N' ,div:'crse'+index}]"
                          :useSubText="false"
                          sequence-key1="cd"
                          nameKey1="cdNm"
                          :div="'crse'+index"
                          :disabled ="isReadOnly"
                      />
                    </div>

                    <div class="kb-form-row" style="justify-content: space-between">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">교육대상</strong>
                      </label>

                      <label class="kb-form-label">
                        <strong class="kb-form-label-text" style="width:100px;">총: {{item.trainTrgtList.length}}건 </strong>
                        <button class="kb-btn kb-btn-sm kb-btn-light-gray " :disabled ="isReadOnly" @click="openSearchModal(index)" >교육대상 추가</button>
                      </label>

                    </div>
                    <div class="kb-form-row" style="text-align: right;" >

                    </div>

                    <article class="content-section ">


                      <div class="kb-table kb-table-bordered" style="text-align: center !important;">

                          <table>
                            <thead>
                              <tr>
                                <th class="text-center"><span class="th-title">No.</span></th>
                                <th class="text-center"><span class="th-title">성명</span></th>
                                <th class="text-center"><span class="th-title">직급</span></th>
                                <th class="text-center"><span class="th-title">부점</span></th>
                                <th class="text-center"><span class="th-title">직원번호</span></th>
                                <th class="text-center"><span class="th-title"></span></th>
                              </tr>
                            </thead>
                            <tbody  v-if="item.trainTrgtList.length >0">
                            <tr v-for="(apply, idx) in item.trainTrgtList" :key="idx">
                              <td class="text-center"><strong class="td-text">{{idx+1}}</strong></td>
                              <td class="text-center"><strong class="td-text">{{ apply.lrnerNm }}</strong></td>
                              <td class="text-center"><strong class="td-text">{{ apply.jbgdNm}}</strong></td>
                              <td class="text-center"><strong class="td-text">{{ apply.deptNm }}</strong></td>
                              <td class="text-center"><strong class="td-text">{{ apply.lrnerId }}</strong></td>
                              <td class="text-center"><button :disabled ="isReadOnly" @click="removeTrgt(index,idx,apply)"><i class="icon-x"></i></button></td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr >
                              <td colspan="5" style="text-align: center;">  교육 대상이 없습니다.</td>

                            </tr>
                            </tbody>
                          </table>
                        </div>

                    </article>

                  </div>
                </div>

              </div>

              <div   class="content-section section-divider" style="text-align:right; margin-top: 20px; padding-top: 30px;" >
                <button class="kb-btn kb-btn-sm kb-btn-light-gray" @click="addCrse" :disabled ="isReadOnly" ><i class="icon-plus"></i></button>
              </div>
              <div  class="view-bottom">

                <button  v-if="deptReqSn > 0 && commInfo.stt ==='74' && session.lrnerId === commInfo.reqId" class="kb-btn kb-btn-dark" @click="goEdit" style="margin-right:20px;">
                  <span class="text">수정</span>
                </button>
                <button v-else-if="deptReqSn == null" class="kb-btn kb-btn-dark" @click="goReg" style="margin-right:20px;">
                  <span class="text">등록</span>
                </button>
                <button class="kb-btn kb-btn-outline" @click="goBack">
                  <span class="text">취소</span>
                </button>
              </div>

          </div>

        </div>
      </div>
      <ApplyDeptReqLrnModal v-if="searchModal" v-model="searchModal" v-model:selectTrgtList="regDistList[crseIdx].trainTrgtList"  />
    </template>
    <!-- //main-content -->
  </main>
  <LoadingDiv v-if="isLoading" />
</template>

<script>

import {computed, onBeforeMount, onMounted, reactive, ref, watch} from 'vue';
import {useRoute,useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {
  // collectionCheck,
  convertToStorageBytes, isSuccess, paramCheck,
  setParams,
  // getItem,
  // lengthCheck,
  // setParams,
  timestampToDateFormat,
} from '@/assets/js/util';
import { goBack } from "@/assets/js/modules/common/common";
import LxpMainHeader from "@/components/common/LxpMainHeader";
import Upload from '@/components/support/common/Upload';
import LoadingDiv from "@/components/common/LoadingDiv.vue";
import InputRadio from "@/components/support/common/InputRadio";
import DatepickerRange from "@/components/support/common/DatepickerRange";
import {closeToggles} from '@/assets/js/ui.init';
import navigationUtils from '@/assets/js/navigationUtils';
import ApplyDeptReqLrnModal from "@/views/pages/apply/ApplyDeptReqLrnModal";
import InputText from '@/components/support/common/InputText';
import {useAlert} from "@/assets/js/modules/common/alert";
import {
  ACT_INSERT_DEPT_REQ_TRAIN_MSTS,
  ACT_INSERT_DEPT_REQ_TRAIN_ATCH_FILES,
  ACT_INSERT_DEPT_REQ_TRAIN_DISTS,
  ACT_GET_DEPT_REQ_TRAIN_APPLY,
  ACT_DELETE_DEPT_REQ_TRAIN_DISTS,
} from "@/store/modules/dept/dept";
// import store from "@/store";
import {
  checkUploadBinaries,
  getFileUploadFailResults,
  // showComplete
} from "@/assets/js/modules/support/support-common";
export default {
  name: 'ApplyDeptReqTrainReg',
  components: {InputText,LxpMainHeader,DatepickerRange,InputRadio,Upload,ApplyDeptReqLrnModal,LoadingDiv},
  setup() {
    const checkOptions = [{ cd: 'Y', cdNm: 'Y' ,div:'overseas'},{ cd: 'N', cdNm: 'N' ,div:'overseas'}];
    const checkOptions2 = [{ cd: 'Y', cdNm: 'Y',div:'lunch' },{ cd: 'N', cdNm: 'N' ,div:'lunch'}];
    const checkOptions3 = [{ cd: 'Y', cdNm: 'Y',div:'dup' },{ cd: 'N', cdNm: 'N' ,div:'dup'}];
    const store = useStore();
    const route = useRoute();
    // const mode = computed(()=> route.params.deptReqSn !== undefined ?  'edit':'new');
    const deptReqSn = ref(route.query.deptReqSn);

    const session = computed(() => store.state.auth.session);
    const router = useRouter();
    const crseIdx = ref(0);
    const regDistList = reactive ([{
     deptReqTrainDistSn:null,
     deptReqSn:null,
     crseMstSn:12187,
     distCrseSn:null,
     distCrseNm:'',
     bgngDt:null,
     endDt:null,
     totLrnPerid:0,
     totLrnHr:0,
     totBgt:0,
     eduCost:0,
     crseSumup:'',
     empInsAplyYn:'N',
     trainTrgtList:[],
    }]);
    const addFileList = ref({files: [], removed: [], binaries: []});
    const commInfo = reactive({
      deptReqSn:null,
      crseMstSn:12187,
      crseNm: '',
      eduGoal:'',
      eduCont:'',
      trainNeces:'',
      overseasYn:'N',
      lunchYn:'N',
      deptDupYn:'N',
      compInfo:'',
      compNm:'',
      payMod:'',
      stt:'',
      reqId: session.value.lrnerId,
      reqNm:session.value.lrnerNm,
      reqDeptCd:session.value.deptCd,
      reqDeptNm:session.value.deptNm,
      atchFileList:[],
      removedFiles:[],
    });

    const searchModal = ref(false);
    const toggles = reactive({
      bgngDt: false,
      endDt: false,
      exyn: false,
    });
    const {showLoading, hideLoading} = useAlert();
    const isReadOnly = ref(false);
    const isLoading = ref(false);
    const openSearchModal =(idx)=>{
      crseIdx.value = idx;
      searchModal.value = true;
    }
    const uploadDeptReqTrainAtchFiles = (payload) => {
      return store.dispatch(`dept/${ACT_INSERT_DEPT_REQ_TRAIN_ATCH_FILES}`, payload);
    };

    const attachments = ref([]);
    const {showMessage, showConfirm} = useAlert();
    const addCrse = ()=>{
      regDistList.push({
        deptReqTrainDistSn:null,
        deptReqSn:null,
        crseMstSn:12187,
        distCrseSn:null,
        distCrseNm:'',
        bgngDt:null,
        endDt:null,
        totLrnPerid:0,
        totLrnHr:0,
        totBgt:0,
        eduCost:0,
        crseSumup:'',
        empInsAplyYn:'N',
        trainTrgtList:[],
      })
    }
    const deleteCrse = (index)=>{
      if (regDistList.length === 1){
        showMessage("하나 이상의 과정 정보가 필요합니다");
        return ;
      }else{
        showConfirm({
          text: '과정정보를 삭제하시겠습니까? <br> 작성중인 모든 정보는 저장되지 않습니다.',
          callback: async() => {
            //등록 후 수정시 삭제일 경우 삭제처리
            console.log(regDistList[index].deptReqTrainDistSn);
            if(regDistList[index].deptReqTrainDistSn != null && regDistList[index].deptReqTrainDistSn >0){
              const distRes = await removeDist(isLoading, `dept/${ACT_DELETE_DEPT_REQ_TRAIN_DISTS}`, {deptReqTrainDistSn:regDistList[index].deptReqTrainDistSn});
              if(distRes){
                regDistList.splice(index,1);
                showMessage("삭제되었습니다.");
              }else{
                showMessage("과정 삭제 처리중 오류가 발생했습니다. 관리자에게 문의해주세요!");
              }
            }else{
              regDistList.splice(index,1);
              showMessage("삭제되었습니다.");
            }



          }
        })

      }

    }
    const setDeptReqTrainFileList = (files, target) => {
      if (files && files.length > 0) {
        files.map( f => {
          let file = {
            deptReqFileAtchSn: f.deptReqFileAtchSn,
            name: f.fileNm,
            size: f.fileSz
          }
          target.value.files.push(file);

        });
      }
    };
    const validate = () => {
      if (paramCheck(commInfo.eduGoal)) {
        showMessage('연수 목적을 입력해주세요.');
        return false;
      }

      return true;
    };
    const goEdit = async() => {

      // showLoading();

      if(addFileList.value.removed.length){
        commInfo.removedFiles = addFileList.value.removed;
      }

      //차수 정보 수정
      const applyRes = await saveReq(isLoading,`dept/${ACT_INSERT_DEPT_REQ_TRAIN_MSTS}`,commInfo,validate);
      // 첨부파일 추가 처리
      let certRes = null;
      if (checkUploadBinaries(addFileList)) {
        certRes = await uploadDeptReqTrainAtchFiles({
          params: {deptReqSn: commInfo.deptReqSn},
          files: addFileList.value.files
        });
      }

      let failStr = getFileUploadFailResults(certRes);


      if (isSuccess(applyRes) && !failStr) {
        //차수 수정 처리
          const distRes = await saveDist(isLoading, `dept/${ACT_INSERT_DEPT_REQ_TRAIN_DISTS}`, regDistList);
          if(isSuccess(distRes)){
            // showMessage('등록 되었습니다.',router.push({name: 'ApplyDeptReqTrain'}));
            showMessage('수정 되었습니다.',router.push({name: 'ApplyDeptReqTrain'}));
          }else{
            showMessage('차수 수정에 실패하였습니다. 관리자에게 문의해주세요!');
          }

        }else{
          showMessage('과정 수정에 실패하였습니다. 관리자에게 문의해주세요!');
        }
      hideLoading();
    }

  const getDeptReq = async ()=>{
    isLoading.value = true;
    store.dispatch(`dept/${ACT_GET_DEPT_REQ_TRAIN_APPLY}`, deptReqSn.value).then(res => {

      if(res.status.code===200) {
        if(res.deptReqMst){
          console.log(res.deptReqMst)
          setParams(commInfo,res.deptReqMst );
          setDeptReqTrainFileList(res.deptReqMst.atchFileList,addFileList);

        }
        if(res.deptReqDist){
          console.log(res.deptReqDist);
          res.deptReqDist.forEach(x => {
           x.bgngDt  = timestampToDateFormat(x.bgngDt,'yyyy-MM-dd');
           x.endDt  = timestampToDateFormat(x.endDt,'yyyy-MM-dd');
          })

          for(let i=0; i < res.deptReqDist.length -1; i++){
            addCrse();
          }

          setParams(regDistList,res.deptReqDist );
          console.log("commInfo.stt",commInfo.stt);
          if(commInfo.stt !== '74' || session.value.lrnerId !== commInfo.reqId){
            isReadOnly.value = true;
          }
        }
        //차수정보
      }
    });
      isLoading.value = false;
  }


    const saveReq = (isLoading, url, payload, validate) => {
      try {
        if (!isLoading.value) {
          showLoading();
          if (validate instanceof Function && validate()) {
              return store.dispatch(url, payload).then(res => {
                if(res.deptReqSn != null){
                  console.log(res);
                  commInfo.deptReqSn = res.deptReqSn;  // 공통정보에 Sn배정
                  regDistList.forEach(x =>{
                    x.deptReqSn = res.deptReqSn; //차수 등록정보에 배정
                  });
                  return res;
                  // 차수 정보 INSERT

                }else{
                  showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
                }
              });
            }
        }
      } catch {
        showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
        isLoading.value = false;
      }
    };
    const saveDist = (isLoading, url, payload) => {
      try {
            return store.dispatch(url, payload).then(res => {
              if(isSuccess(res)){
                return res;
                // 차수 정보 INSERT
              }else{
                showMessage('차수정보 등록 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
              }
            });
      } catch {
        showMessage('차수정보 등록 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
        isLoading.value = false;
      }
    };

    const removeDist = (isLoading, url, payload) => {
      try {
        return store.dispatch(url, payload).then(res => {
          if(isSuccess(res)){
            return res;
            // 차수 정보 DELETE
          }else{
            showMessage('과정정보 삭제 시 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
          }
        });
      } catch {
        showMessage('과정정보 삭제 시 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
        isLoading.value = false;
      }
    };

    const goReg = async() => {
     showLoading();
      // 신청정보(파일업로드 포함)
      //공통정보 및 차수정보 체크
      let certRes = null;
      //요청정보 등록
      const applyRes = await saveReq(isLoading,`dept/${ACT_INSERT_DEPT_REQ_TRAIN_MSTS}`,commInfo,validate);
      //첨부파일 등록 (신규 등록시에는 파일 업로드만 수정시 삭제 같이 처리)
      if(commInfo.deptReqSn != null && commInfo.deptReqSn !== 0 ){
        if (checkUploadBinaries(addFileList)) {
            certRes = await uploadDeptReqTrainAtchFiles({
            params: {deptReqSn: commInfo.deptReqSn},
            files: addFileList.value.files
          });
        }
      }

      // 첨부파일 등록확인
      let failStr = getFileUploadFailResults(certRes);
      // 요청정보 등록 확인후 차수정보 등록z
      if (isSuccess(applyRes) && !failStr) {
        console.log("applyRes:",applyRes)
        //차수등록
        const distRes = await saveDist(isLoading, `dept/${ACT_INSERT_DEPT_REQ_TRAIN_DISTS}`, regDistList);
        if(isSuccess(distRes)){
          showMessage('등록 되었습니다.',router.push({name: 'ApplyDeptReqTrain'}));
          // showMessage('등록 되었습니다.');
        }else{
          showMessage('차수 등록에 실패하였습니다. 관리자에게 문의해주세요!');
        }

      } else if(applyRes && applyRes.result && applyRes.result.number === 302) {
        showMessage('첨부파일 등록시 오류!');
      }
      hideLoading();

    }

    const removeTrgt = (index,idx,apply)=>{

      showConfirm({
        text: `교육대상자 ${apply.lrnerNm}  (${apply.lrnerId})를 삭제하시겠습니까?`,
        callback: () => {
          regDistList[index].trainTrgtList.splice(idx,1);
        }
      })
    }


    onBeforeMount(()=>{
      if(deptReqSn.value ){ //수정 또는 조회
        getDeptReq();
      }
      // 74 신청중 , 81 승인요청중 , 00 처리완료

    })

    onMounted(() => {

      if(commInfo.deptReqSn != null  && commInfo.deptReqSn !== 0){
        setDeptReqTrainFileList(commInfo.atchFileList, addFileList);
      }

    });

    watch(() => addFileList.value, () => {
      console.log(addFileList);
    })

    return {
      session,
      searchModal,
      // lrnPostSn,
      // params,
      attachments,
      toggles,
      goEdit,
      goReg,
      // downloadAttachment,
      checkOptions3,
      checkOptions2,
      checkOptions,
      timestampToDateFormat,
      convertToStorageBytes,
      closeToggles,
      regDistList,
      isMobile: navigationUtils.any(),
      commInfo,
      addCrse,
      deleteCrse,
      openSearchModal,
      crseIdx,
      isLoading,
      addFileList,
      goBack,
      deptReqSn,
      removeTrgt,
      isReadOnly,
    }
  }
};
</script>
